import {createRouter,createWebHashHistory} from "vue-router";
const routes =[
	{
		//登录界面
		path:'/',
		name:'login',
		component:()=>import(/*webpackChunkName:'Login'*/ '@/page/login/login.vue'),
		meta: {
			title: '成都莱怀特网络科技服务有限公司',
			keepAlive: true, // 需要被缓存
		}
	}
]

const router = createRouter({
	history:createWebHashHistory(),
	routes
})
export default router