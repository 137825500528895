import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import router from './router/index.js'
router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title == undefined?'默认标题':to.meta.title
    if (to.meta.requireAuth) {
        let token = Cookies.get('access_token');
        let anonymous = Cookies.get('user_name');
        if (token) { 
            
                next({
                    path: '/login',
                    query: {
                        redirect: to.fullPath
                    } 
                })
          
        }
    }
    next();
})
const app = createApp(App)
app.use(ElementPlus,{
	locale:zhCn
})

app.use(router)
app.mount('#app')
